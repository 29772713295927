import moment from "moment";
import XLSX from "xlsx";
import { calculateActive, calculateState, calculateUsage, groupBy, round } from "./common";

function chooseMeterId(device, waterType) {
    if (device.meter1WaterType === waterType) {
        return device.meter1Id;
    } else if (device.meter2WaterType === waterType) {
        return device.meter2Id;
    }
    return null;
}

function chooseMeterSn(device, waterType) {
    if (device.meter1WaterType === waterType) {
        return device.meter1Sn;
    } else if (device.meter2WaterType === waterType) {
        return device.meter2Sn;
    }
    return null;
}

const addressPattern = /^((?<town>[a-zA-Z0-9ąćęłńóśzĄĆĘŁŃÓŚŹŻ -]+),\s+)?(?<street>[a-zA-Z0-9ąćęłńóśzĄĆĘŁŃÓŚŹŻ -]+)\s(?<house>[0-9a-zA-Z]+)\/?(?<apartment>[0-9a-zA-Z]+)?$/;

// Blueprint 1
export function iniElblag(devices, deviceStatusesByDeviceId) {
    //numer_seryjny_modułu;data;adres;numer_seryjny_licznika;rodzaj(zw,cw,co);opis

    /*
    "ID": inputRow["ID"],
        "data": moment(inputRow["Czas"]).format("DD.MM.YYYY"), // Formatowanie daty
        "adres_klienta": inputRow["Adres klienta"],
        "id_licznika_ZW": inputRow["ID licznika ZW"],
        "typ_ZW": "ZW", // Wpisane na sztywno
        "id_licznika_CW": inputRow["ID licznika CW"],
        "typ_CW": "CW", // Wpisane na sztywno
        "id_licznika_CO": inputRow["ID licznika ciepła"],
        "typ_CO": "CO", // Wpisane na sztywno
        "opis": inputRow["Opis"]
    */

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.id,
            (statuses && statuses[0]) ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
            device.clientAddress,
            (device.meter1Sn != null && device.meter1Sn !== "") ? device.meter1Sn : device.meter1Id,
            device.meter1WaterType === "COLD" ? "ZW" : (device.meter1WaterType === "HOT" ? "CW" : (device.meter1WaterType === "HEAT" ? "CO" : null)),
            device.description
        ] : null;

        const meter2 = device.meter2Active ? [
            device.id,
            (statuses && statuses[0]) ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
            device.clientAddress,
            (device.meter2Sn != null && device.meter2Sn !== "") ? device.meter2Sn : device.meter2Id,
            device.meter2WaterType === "COLD" ? "ZW" : (device.meter2WaterType === "HOT" ? "CW" : (device.meter2WaterType === "HEAT" ? "CO" : null)),
            device.description
        ] : null;

        const meter3 = device.meter3Active ? [
            device.id,
            (statuses && statuses[0]) ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
            device.clientAddress,
            (device.meter3Sn != null && device.meter3Sn !== "") ? device.meter3Sn : device.meter3Id,
            device.meter3WaterType === "COLD" ? "ZW" : (device.meter3WaterType === "HOT" ? "CW" : (device.meter3WaterType === "HEAT" ? "CO" : null)),
            device.description
        ] : null;

        if (meter1 && meter2 && meter3) {
            return [meter1, meter2, meter3];
        } else if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1 && meter3) {
            return [meter1, meter3];
        } else if (meter2 && meter3) {
            return [meter2, meter3];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else if (meter3) {
            return [meter3];
        } else {
            return [];
        }
    });

    const csv = rows
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function gliwiceSmop(devices, deviceStatusesByDeviceId) {
    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            (device.meter1Sn != null && device.meter1Sn !== "") ? device.meter1Sn : device.meter1Id,
            statuses && statuses.length > 0 ? calculateState(device, statuses[0], device.meter1WaterType).value : null,
            (statuses && statuses[0]) ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
        ] : null;

        const meter2 = device.meter2Active ? [
            (device.meter2Sn != null && device.meter2Sn !== "") ? device.meter2Sn : device.meter2Id,
            statuses && statuses.length > 0 ? calculateState(device, statuses[0], device.meter2WaterType).value : null,
            (statuses && statuses[0]) ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const worksheet = XLSX.utils.aoa_to_sheet(rows);
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Metering");

    return new Blob([XLSX.write(new_workbook, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'array'
    })], { type: "application/octet-stream" });
}

// Blueprint 1
export function skarszewianka(devices, deviceStatusesByDeviceId) {
    const columns = [
        "Numer_Licznika",
        "Data_Odczytu",
        "Odczyt",
        "Adres"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            (device.meter1Sn != null && device.meter1Sn !== "") ? device.meter1Sn : device.meter1Id,
            (statuses && statuses[0]) ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            statuses && statuses.length > 0 ? calculateState(device, statuses[0], device.meter1WaterType).value : null,
            device.clientAddress
        ] : null;

        const meter2 = device.meter2Active ? [
            (device.meter2Sn != null && device.meter2Sn !== "") ? device.meter2Sn : device.meter2Id,
            (statuses && statuses[0]) ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            statuses && statuses.length > 0 ? calculateState(device, statuses[0], device.meter2WaterType).value : null,
            device.clientAddress
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const worksheet = XLSX.utils.aoa_to_sheet([columns].concat(rows));
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Metering");

    return new Blob([XLSX.write(new_workbook, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'array'
    })], { type: "application/octet-stream" });
}

// Blueprint 1
export function smMarcel(devices, deviceStatusesByDeviceId) {
    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.meter1Id,
            statuses && statuses.length > 0 ? calculateState(device, statuses[0], device.meter1WaterType).value : null,
            (statuses && statuses[0]) ? moment(statuses[0].time).format("DD-MM-YYYY") : null,
            "",
            "",
            "odczyt zdalny Fila"
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter2Id,
            statuses && statuses.length > 0 ? calculateState(device, statuses[0], device.meter2WaterType).value : null,
            (statuses && statuses[0]) ? moment(statuses[0].time).format("DD-MM-YYYY") : null,
            "",
            "",
            "odczyt zdalny Fila"
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const worksheet = XLSX.utils.aoa_to_sheet(rows);
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Metering");

    return new Blob([XLSX.write(new_workbook, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'array'
    })], { type: "application/octet-stream" });
}

// Blueprint 1
export function dabrowaGorniczaManhattan(devices, deviceStatusesByDeviceId) {

    const columns = [
        "numer_licznika",
        "stan_wodomierza_na_dzien",
        "data_odczytu",
        "pusta_kolumna_1",
        "pusta_kolumna_2",
        "odczyt_radiowy"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.meter1Id,
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter1WaterType).value, 2).toString().replace(/\./g, ',') : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
            "",
            "",
            "odczyt radiowy"
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter2Id,
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter2WaterType).value, 2).toString().replace(/\./g, ',') : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
            "",
            "",
            "odczyt radiowy"
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const worksheet = XLSX.utils.aoa_to_sheet([columns]
        .concat(rows));
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Metering");

    return new Blob([XLSX.write(new_workbook, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'array'
    })], { type: "application/octet-stream" });
}

// Blueprint 1
export function krosnoOdrzanskie(devices, deviceStatusesByDeviceId) {

    // Poniżej specyfikacja:
    // 1) Separator pól: średnik ";"
    // 2) Nie wymagany wyróżnik pól tekstowych
    // 3) Struktura pliku:
    // Lista pól:

    //    - ulica- nie jest wymagana wartość

    //    - nr lokalu - nie jest wyagana wartość

    //    - pole nieistotne

    //    - nazwisko - nie jest wymagana wartość

    //    - pole nieistotne

    //    - umiejscowienie urzadzenia - nie jest wymagana wartosć

    //    - nr urządzenia -wg tej kolumny nastepuje rozpoznanie urządzenia pomiarowego

    //    - rodzaj urządzania - nie jest wymagana wartość

    //    - data montażu - nie jest wymagan wartość

    //   - data odczytu - format DD.MM.RRRR lub RRRR.MM.DD

    //   -  odczyt - separator dziesiętny kropka lub przecinek

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            "",
            "",
            "",
            "",
            "",
            "",
            device.meter1Id,
            "",
            "",
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY.MM.DD") : null,
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter1WaterType).value, 2).toString().replace(/\./g, ',') : null
        ] : null;

        const meter2 = device.meter2Active ? [
            "",
            "",
            "",
            "",
            "",
            "",
            device.meter2Id,
            "",
            "",
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY.MM.DD") : null,
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter2WaterType).value, 2).toString().replace(/\./g, ',') : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = rows
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function gdanskBizan(devices, deviceStatusesByDeviceId) {

    // Raport według wzoru:
    // nr_mieszkania,numer_wodomierza;typ_wodomierza;stan;data
    // Numer mieszkania musi być poprzedzony "M" czyli np. M11,M13,M14
    // Typ wodomierza, ZW lub CW
    // Stan Wodomierza: Podajemy tylko ilość metrów bez miejsc po przecinku
    // Data w formacie rok-miesiąc-dzień
    // Wszystko w jednej kolumnie bez żadnego separatora (kolumny oddzielone tylko tabulatorem) ma być w ciągu np: M151 22260068 ZW 85 2024-01-02 , ale oddzielone tylko tabulatorem
    // Nagłówek tabeli: MIE NR TYP WAR DATA
    // Rodzaj raportu CSV
    // Mieszkanie to indeks mieszkania - brany u nas z kolumny ID inkasenta
    // Konieczne jest jeden wiersz od góry wolny i pierwsza kolumna też wolna

    const empty = [
        "",
        "",
        "",
        "",
        "",
        ""
    ];

    const columns = [
        "",
        "MIE",
        "NR",
        "TYP",
        "WAR",
        "DATA"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            "",
            device.collectorId,
            device.meter1Id,
            device.meter1WaterType === "HOT" ? "CW" : "ZW",
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter1WaterType).value, 0).toString().replace(/\./g, ',') : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null
        ] : null;

        const meter2 = device.meter2Active ? [
            "",
            device.collectorId,
            device.meter2Id,
            device.meter2WaterType === "HOT" ? "CW" : "ZW",
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter2WaterType).value, 0).toString().replace(/\./g, ',') : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = [empty, columns]
        .concat(rows)
        .map(e => e.join("\t"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function brzezinyBsm(devices, deviceStatusesByDeviceId) {

    // Id lokalu
    // Numer wodomierza
    // Licznik wodomierza
    // Ulica
    // Nr domu
    // Nr mieszkania

    // format csv
    // z nagłówkiem (wymienione wyżej nazwy kolumn)
    // separator średnik
    // stan wodomierza z przecinkiem
    // każda kolumna cytowana

    const columns = [
        "ID lokalu",
        "Numer wodomierza",
        "Licznik wodomierza",
        "Ulica",
        "Nr domu",
        "Nr mieszkania"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const { town, street, house, apartment } = addressPattern.exec(device.clientAddress) ? addressPattern.exec(device.clientAddress).groups : { town: null, street: null, hause: null, apartment: null };

        const meter1 = device.meter1Active ? [
            device.clientId,
            device.meter1Id,
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter1WaterType).value, 2).toString().replace(/\./g, ',') : null,
            street,
            house,
            apartment
        ] : null;

        const meter2 = device.meter2Active ? [
            device.clientId,
            device.meter1Id,
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter1WaterType).value, 2).toString().replace(/\./g, ',') : null,
            street,
            house,
            apartment
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = [columns]
        .concat(rows)
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function dwikozy(devices, deviceStatusesByDeviceId) {

    // ID - nr modułu bez 38f7cd00
    // Data - rrrr-mm-dd
    // ID klienta - brane z kolumny id inkasenta
    // Adres klienta
    // numer wodomierza
    // Stan

    const columns = [
        "ID",
        "Data",
        "ID klienta",
        "Adres klienta",
        "numer wodomierza",
        "Stan"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.name.replace(/^38f7cd00/, ''),
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            device.collectorId,
            device.clientAddress,
            device.meter1Id,
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter1WaterType).value, 2).toString().replace(/\./g, ',') : null
        ] : null;

        const meter2 = device.meter2Active ? [
            device.name.replace(/^38f7cd00/, ''),
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            device.collectorId,
            device.clientAddress,
            device.meter2Id,
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter2WaterType).value, 2).toString().replace(/\./g, ',') : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = [columns]
        .concat(rows)
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function smGrunwald(devices, deviceStatusesByDeviceId) {

    // ID - nr modułu bez 38f7cd00
    // Data - rrrr-mm-dd
    // ID klienta - brane z kolumny id inkasenta
    // Adres klienta
    // numer wodomierza
    // Stan

    // const columns = [
    //     "Typ rekordu",
    //     "Nr nieruchomości",
    //     "Nr użytkownika",
    //     "Adres",
    //     "Kod lokalu",
    //     "Kod pomieszczenia",
    //     "Rodzaj wodomierza",
    //     "Numer wodomierza",
    //     "Miesiąc odczytu",
    //     "Wskazanie",
    //     "Kod błędu",
    //     "Dodatkowa informacja",
    //     "Kod wstecznego przepływu",
    //     "Wartość przepływu w litrach"
    // ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            "O1",
            "999",
            "0",
            device.clientAddress,
            device.clientId,
            device.description ? device.description.includes("łazienka") ? "02" : device.description.includes("kuchnia") ? "06" : "99" : null,
            device.meter1WaterType === "COLD" ? 0 : 1,
            device.meter1Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYMM") : null,
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter1WaterType).value * 1000, 0).toString().padStart(8, '0') : null,
            statuses && statuses.length > 0 && statuses[0].isAlarm ? "B0" : "",
            null,
            null,
            null
        ] : null;

        const meter2 = device.meter2Active ? [
            "O1",
            "999",
            "0",
            device.clientAddress,
            device.clientId,
            device.description ? device.description.includes("łazienka") ? "02" : device.description.includes("kuchnia") ? "06" : "99" : null,
            device.meter2WaterType === "COLD" ? 0 : 1,
            device.meter2Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYMM") : null,
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter2WaterType).value * 1000, 0).toString().padStart(8, '0') : null,
            statuses && statuses.length > 0 && statuses[0].isAlarm ? "B0" : "",
            null,
            null,
            null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = rows
        .map(e => e.join(";"))
        .join(";\r\n") + ";\r\n";

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function pyskowice(devices, deviceStatusesByDeviceId) {
    const columns = [
        "Numer ewidencyjny licznika",
        "Wartość odczytu",
        "Data odczytu"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.meter1Id,
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter1WaterType).value, 2) : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter2Id,
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter2WaterType).value, 2) : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const worksheet = XLSX.utils.aoa_to_sheet([columns].concat(rows));
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Metering");

    return new Blob([XLSX.write(new_workbook, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'array'
    })], { type: "application/octet-stream" });
}

// Blueprint 1
// BTBS Bytów
export function btbsBytow(devices, deviceStatusesByDeviceId) {
    const columns = [
        "Numer ewidencyjny licznika",
        "Wartość odczytu",
        "Data odczytu"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.meter1Id,
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter1WaterType).value, 3) : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter2Id,
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter2WaterType).value, 3) : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const worksheet = XLSX.utils.aoa_to_sheet([columns].concat(rows));
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Metering");

    return new Blob([XLSX.write(new_workbook, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'array'
    })], { type: "application/octet-stream" });
}

// Blueprint 1
export function wegrow(devices, deviceStatusesByDeviceId) {
    const columns = [
        "Numer licznika",
        "Numer ewidencyjny licznika",
        "Data odczytu",
        "Wartość odczytu"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.meter1Id,
            "",
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter1WaterType).value, 2) : null
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter2Id,
            "",
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter2WaterType).value, 2) : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const worksheet = XLSX.utils.aoa_to_sheet([columns].concat(rows));
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Metering");

    return new Blob([XLSX.write(new_workbook, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'array'
    })], { type: "application/octet-stream" });
}

// Blueprint 1
export function bystrzycaKlodzka(devices, deviceStatusesByDeviceId) {

    const columns = [
        "L.P.",
        "Nr modułu",
        "Nazwisko",
        "Imię",
        "Numer wodomierza",
        "Id lokalu",
        "Ulica",
        "Nr domu",
        "Nr mieszkania",
        "Miasto",
        "Czas odczytu",
        "Czas radio",
        "Licznik wodomierza"
    ];

    const rows = devices.flatMap((device, index) => {
        const statuses = deviceStatusesByDeviceId.get(device.id);
        const clientAddress = device.clientAddress.split(/[\s\/]+/);

        const { town, street, house, apartment } = addressPattern.exec(device.clientAddress) ? addressPattern.exec(device.clientAddress).groups : { town: null, street: null, hause: null, apartment: null };


        const meter1 = device.meter1Active ? [
            index + 1,
            device.name.replace(/^38f7cd/, ''),
            "",
            "",
            device.meter1Id,
            device.clientId,
            street,
            house,
            apartment,
            "",
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
            statuses && statuses.length > 0 ? moment(statuses[0].recvTime).format("DD.MM.YYYY") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        const meter2 = device.meter2Active ? [
            index + 1,
            device.name.replace(/^38f7cd/, ''),
            "",
            "",
            device.meter2Id,
            device.clientId,
            street,
            house,
            apartment,
            "",
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
            statuses && statuses.length > 0 ? moment(statuses[0].recvTime).format("DD.MM.YYYY") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = [columns]
        .concat(rows)
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function silesiaSlonie(devices, deviceStatusesByDeviceId) {

    // numer_licznika;stan_licznika;stan_licznika;

    // Stan licznika ma być oddzielony "," a data w formacie dd.mm.rrrr
    // Formaty raportu xls

    const columns = [
        "Numer licznika",
        "Stan licznika",
        "Data odczytu"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.meter1Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString().replace(/\./g, ',') : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter2Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString().replace(/\./g, ',') : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const worksheet = XLSX.utils.aoa_to_sheet([columns].concat(rows));
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Metering");

    return new Blob([XLSX.write(new_workbook, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'array'
    })], { type: "application/octet-stream" });
}

// Blueprint 1
export function warszawaGgko(devices, deviceStatusesByDeviceId) {

    // Dzień dobry, kontaktuje się w sprawie raportu dla zarządcy Warszawa GGKO

    // Wzór kolumn:

    // nr_wodomierza;data;stan

    // Data: dzień,miesiąc,rok
    // Separator stanu wodomierza: „,” (przecinek)

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.meter1Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter1Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const worksheet = XLSX.utils.aoa_to_sheet(rows);
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Metering");

    return new Blob([XLSX.write(new_workbook, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'array'
    })], { type: "application/octet-stream" });
}

// Blueprint 1
export function gdanskEnergetyk(devices, deviceStatusesByDeviceId) {

    // Proszę o dodanie nowego raportu:
    // Gdańsk Energetyk

    // kolumny:
    // 1.nr modułu bez 38fcd
    // 2.adres
    // 3. pusta
    // 4. pusta
    // 5. numer wodomierza
    // 6. stan

    // Moduły są podwójne więc jeden pod drugim zimna/ciepła

    //sortowanie po adresie

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.name.replace(/^38f7cd/, ''),
            device.clientAddress,
            "",
            "",
            device.meter1Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        const meter2 = device.meter2Active ? [
            device.name.replace(/^38f7cd/, ''),
            device.clientAddress,
            "",
            "",
            device.meter2Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const sortedRows = rows.sort((a, b) => (a[1] < b[1]) ? -1 : ((b[1] > a[1]) ? 1 : 0));

    const csv = sortedRows
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function omZuk(devices, deviceStatusesByDeviceId) {

    // Proszę o dodanie nowego raportu OM ZUK

    // Kolumny:

    // indeks lokalu
    // numer licznika
    // data odczytu
    // stan licznika


    // bez nagłówka, format csv, separator średnik.

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.clientId,
            device.meter1Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY/MM/DD") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        const meter2 = device.meter2Active ? [
            device.clientId,
            device.meter2Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY/MM/DD") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = rows
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function wloszakowice(devices, deviceStatusesByDeviceId) {

    // Numer wodomierza
    // Czas Odczytu
    // Licznik Wodomierza
    // Id lokalu (brane z kolumny id klienta)
    // Nr modułu bez 38f7cd00
    // Nazwisko i Imie (pusta kolumna)
    // Miasto (pusta kolumna)
    // Adres

    // format csv
    // separator średnik

    const columns = [
        "Numer wodomierza",
        "Czas Odczytu",
        "Licznik Wodomierza",
        "Id lokalu",
        "Nr modułu",
        "Nazwisko i Imie",
        "Miasto",
        "Adres"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.meter1Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString().replace(/\./g, ',') : null,
            device.clientId,
            device.name.replace(/^38f7cd00/, ""),
            "",
            "",
            device.clientAddress
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter2Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD-MM-YYYY") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString().replace(/\./g, ',') : null,
            device.clientId,
            device.name.replace(/^38f7cd00/, ""),
            "",
            "",
            device.clientAddress
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = [columns]
        .concat(rows)
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function czestochowaZnCtg(devices, deviceStatusesByDeviceId) {
    //  "Częstochowa ZN CTG"

    //nr wodomierza, odczyt, data odczytu, format xls

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.meter1Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString().replace(/\./g, ',') : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter2Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString().replace(/\./g, ',') : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null
        ] : null;

        const meter3 = device.meter3Active ? [
            device.meter3Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter3WaterType).value ? calculateState(device, statuses[0], device.meter3WaterType).value.toString().replace(/\./g, ',') : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null
        ] : null;

        if (meter1 && meter2 && meter3) {
            return [meter1, meter2, meter3];
        } else if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1 && meter3) {
            return [meter1, meter3];
        } else if (meter2 && meter3) {
            return [meter2, meter3];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else if (meter3) {
            return [meter3];
        } else {
            return [];
        }
    });

    const worksheet = XLSX.utils.aoa_to_sheet(rows);
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Metering");

    return new Blob([XLSX.write(new_workbook, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'array'
    })], { type: "application/octet-stream" });
}

// Blueprint 1
export function raciborzDomEk(devices, deviceStatusesByDeviceId) {
    // plik w formacie csv oddzielony średnikiem
    // Nazwa: "Racibórz DOM_EK"

    // Nagłówek na sztywno: Numer;Data;Stan

    // Kolumny w formacie poniżej:

    // 12345678;2023-09-30,123.456

    const columns = [
        "Numer",
        "Data",
        "Stan"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.meter1Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString() : null
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter2Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString() : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = [columns]
        .concat(rows)
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function osmProbit(devices, deviceStatusesByDeviceId) {
    // plik w formacie csv oddzielony średnikiem

    // kolumna 1 - id licznika - ZW lub CW w zależności od rodzaju wodomierza
    // kolumna 2 - id klienta
    // kolumna 3 - adres (bez polskich znaków jeśli się da)
    // kolumna 4 - stan licznika oddzielony przecinkiem

    //NameFile = NameFile.replace("ó","");
    // NameFile = NameFile.replace("Ó","");
    // NameFile = NameFile.replace("ł","");
    // NameFile = NameFile.replace("Ł","");
    // NameFile = NameFile.replace("ń","");
    // NameFile = NameFile.replace("Ń","");
    // NameFile = NameFile.replace("ż","");
    // NameFile = NameFile.replace("Ż","");
    // NameFile = NameFile.replace("ź","");
    // NameFile = NameFile.replace("Ź","");
    // NameFile = NameFile.replace("Ć","");
    // NameFile = NameFile.replace("ć","");
    // NameFile = NameFile.replace("ę","");
    // NameFile = NameFile.replace("Ę","");
    // NameFile = NameFile.replace("Ś","");
    // NameFile = NameFile.replace("ś","");

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const clientAddress = device.clientAddress
            .replace("ą", "a")
            .replace("Ą", "A")
            .replace("ć", "c")
            .replace("Ć", "C")
            .replace("ę", "e")
            .replace("Ę", "E")
            .replace("ł", "l")
            .replace("Ł", "L")
            .replace("ń", "n")
            .replace("Ń", "N")
            .replace("o", "o")
            .replace("Ó", "O")
            .replace("ś", "s")
            .replace("Ś", "S")
            .replace("ź", "z")
            .replace("Ź", "Z")
            .replace("ż", "z")
            .replace("Ż", "Z");

        const meter1 = device.meter1Active ? [
            device.meter1Id,
            device.clientId,
            clientAddress,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter2Id,
            device.clientId,
            clientAddress,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = rows
        .map(e => e.map(a => "\"" + a + "\"").join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function warszawaVictus(devices, deviceStatusesByDeviceId) {
    // Nazwa: "Warszawa Victus"

    // format xls - bez nagłówków

    // Kolumny:
    // Numer wodomierza
    // Stan wodomierza - stan po przecinku
    // data -  rrrr-mm-dd

    // Sortowany po adresi

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.meter1Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString().replace(/\./g, ',') : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter2Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString().replace(/\./g, ',') : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const worksheet = XLSX.utils.aoa_to_sheet(rows);
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Metering");

    return new Blob([XLSX.write(new_workbook, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'array'
    })], { type: "application/octet-stream" });
}

// Blueprint 1
export function gdanskHomesystemC(devices, deviceStatusesByDeviceId) {
    const columns = [
        "adres",
        "nr wodomierza",
        "odczyty"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = (device.meter1Active && device.meter1WaterType === "HEAT") ? [
            device.clientAddress,
            device.meter1Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        const meter2 = (device.meter2Active && device.meter2WaterType === "HEAT") ? [
            device.clientAddress,
            device.meter2Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = [columns]
        .concat(rows)
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function gdanskHomesystemW(devices, deviceStatusesByDeviceId) {
    const columns = [
        "adres",
        "nr wodomierza",
        "odczyty"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = (device.meter1Active && device.meter1WaterType !== "HEAT") ? [
            device.clientAddress,
            device.meter1Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        const meter2 = (device.meter2Active && device.meter2WaterType !== "HEAT") ? [
            device.clientAddress,
            device.meter2Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = [columns]
        .concat(rows)
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function dabrowaTarnowskaSm(devices, deviceStatusesByDeviceId) {
    const columns = [
        "Nr wodomierza",
        "stan wodomierza",
        "data",
        "Adres"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.meter1Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString().replace(/\./g, ',') : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            device.clientAddress
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter2Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString().replace(/\./g, ',') : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            device.clientAddress
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = [columns]
        .concat(rows)
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function warszawaPolitechnika(devices, deviceStatusesByDeviceId) {
    const year = moment().format("YYYY");
    var month = "";

    switch (moment().format("MM")) {
        case '01':
            month = "styczeń"
            break;
        case '02':
            month = "luty"
            break;
        case '03':
            month = "marzec"
            break;
        case '04':
            month = "kwiecień"
            break;
        case '05':
            month = "maj"
            break;
        case '06':
            month = "czerwiec"
            break;
        case '07':
            month = "lipiec"
            break;
        case '08':
            month = "sierpień"
            break;
        case '09':
            month = "wrzesień"
            break;
        case '10':
            month = "październik"
            break;
        case '11':
            month = "listopad"
            break;
        case '12':
            month = "grudzień"
            break;
        default:
            ""
    };

    const columns = [
        "Nazwa punktu pomiarowego",
        "Grupa",
        "Budynek",
        "Klatka",
        "Lokal",
        "Właściciel",
        "Uwagi",
        "Lokalizacja",
        "Mierzona wielkość",
        "Typ urządzenia",
        "Nr wodomierza",
        "Nr radiowy",
        "Nazwa punktu pomiarowego",
        "Objętość [m3] " + month + " " + year + " (miesiąc odczytu)"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);
        const clientAddress = device.clientAddress.split(/[\s\/]+/);

        const meter1 = device.meter1Active ? [
            device.meter1WaterType === "HOT" || device.meter1WaterType === "HEAT" ? device.name.replace(/^38f7cd00/, '') + "0" : device.name.replace(/^38f7cd00/, ''),
            "",
            clientAddress.length > 1 ? clientAddress[1] : null,
            "",
            clientAddress.length > 2 ? clientAddress[2] : null,
            device.clientId,
            "",
            "",
            device.meter1WaterType === "HOT" || device.meter1WaterType === "HEAT" ? "CW" : "ZW",
            "",
            device.meter1Id,
            device.meter1WaterType === "HOT" || device.meter1WaterType === "HEAT" ? device.name.replace(/^38f7cd00/, '') + "0" : device.name.replace(/^38f7cd00/, ''),
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter2WaterType === "HOT" || device.meter2WaterType === "HEAT" ? device.name.replace(/^38f7cd00/, '') + "0" : device.name.replace(/^38f7cd00/, ''),
            "",
            clientAddress.length > 1 ? clientAddress[1] : null,
            "",
            clientAddress.length > 2 ? clientAddress[2] : null,
            device.clientId,
            "",
            "",
            device.meter2WaterType === "HOT" || device.meter2WaterType === "HEAT" ? "CW" : "ZW",
            "",
            device.meter2Id,
            device.meter2WaterType === "HOT" || device.meter2WaterType === "HEAT" ? device.name.replace(/^38f7cd00/, '') + "0" : device.name.replace(/^38f7cd00/, ''),
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = [columns]
        .concat(rows)
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Grupa - pusta kolumna
// Budynek - będzie brany z adresu
// Klatka - pusta kolumna
// Lokal - będzie brany z adresu
// Właściciel - Imię i Nazwisko
// Lokalizacja - pusta kolumna
// Uwagi - pusta kolumna
// Mierzona wielkość - ZW/CW w zależności który wodomierz
// Typ urządzenia - pusta kolumna
// Nr radiowy - numer modułu
// Nr wodomierza - nr wodomierza
// Data odczytu - format dd.mm.rrrr
// Objętość [m3] czerwiec 2023 (miesiąc odczytu) - wkazania na wybrany dzień, czy nazwa miesiąca w naglówku może się zmieniać w zależności kiedy pobieramy raport?

// Nagłówki muszą być w pierwszym wierszu dokładnie takie jak we wskazanym wzorcu
// Sortowanie po adresie
// Format CSV, separator średnik

// Blueprint 1
export function ketrzynZn(devices, deviceStatusesByDeviceId) {
    const year = moment().format("YYYY");
    var month = "";

    switch (moment().format("MM")) {
        case '01':
            month = "styczeń"
            break;
        case '02':
            month = "luty"
            break;
        case '03':
            month = "marzec"
            break;
        case '04':
            month = "kwiecień"
            break;
        case '05':
            month = "maj"
            break;
        case '06':
            month = "czerwiec"
            break;
        case '07':
            month = "lipiec"
            break;
        case '08':
            month = "sierpień"
            break;
        case '09':
            month = "wrzesień"
            break;
        case '10':
            month = "październik"
            break;
        case '11':
            month = "listopad"
            break;
        case '12':
            month = "grudzień"
            break;
        default:
            ""
    };

    const columns = [
        "Grupa",
        "Budynek",
        "Klatka",
        "Lokal",
        "Właściciel",
        "Lokalizacja",
        "Uwagi",
        "Mierzona wielkość",
        "Typ urządzenia",
        "Nr radiowy",
        "Nr wodomierza",
        "Data odczytu",
        "Objętość [m3] " + month + " " + year + " (miesiąc odczytu)"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);
        const clientAddress = device.clientAddress.split(/[\s\/]+/);

        const meter1 = device.meter1Active ? [
            "",
            clientAddress.length > 1 ? clientAddress[1] : null,
            "",
            clientAddress.length > 2 ? clientAddress[2] : null,
            device.clientId,
            "",
            "",
            device.meter1WaterType === "HOT" || device.meter1WaterType === "HEAT" ? "CW" : "ZW",
            "",
            device.name.replace(/^38f7cd00/, ''),
            device.meter1Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString() : null
        ] : null;

        const meter2 = device.meter2Active ? [
            "",
            clientAddress.length > 1 ? clientAddress[1] : null,
            "",
            clientAddress.length > 2 ? clientAddress[2] : null,
            device.clientId,
            "",
            "",
            device.meter2WaterType === "HOT" || device.meter2WaterType === "HEAT" ? "CW" : "ZW",
            "",
            device.name.replace(/^38f7cd00/, ''),
            device.meter2Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString() : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = [columns]
        .concat(rows.filter(v => v[7] == "CW"))
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 2
export function gdanskKo(devices, deviceStatusesByDeviceId) {
    const columns = [
        "Data odczytu",
        "Adres klienta",
        "Numer licznika ZW",
        "Stan ZW",
        "Numer licznika CW",
        "Stan CW",
        "Numer licznika ciepła",
        "Stan ciepła"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        return [[
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
            device.clientAddress,
            chooseMeterId(device, "COLD"),
            statuses && statuses.length > 0 ? calculateState(device, statuses[0], "COLD").value : null,
            chooseMeterId(device, "HOT"),
            statuses && statuses.length > 0 ? calculateState(device, statuses[0], "HOT").value : null,
            chooseMeterId(device, "HEAT"),
            statuses && statuses.length > 0 ? calculateState(device, statuses[0], "HEAT").value : null
        ]]
    });

    const sortedRows = rows.sort((a, b) => (a[1] < b[1]) ? -1 : ((b[1] > a[1]) ? 1 : 0));

    const worksheet = XLSX.utils.aoa_to_sheet([columns]
        .concat(sortedRows));
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Metering");

    return new Blob([XLSX.write(new_workbook, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'array'
    })], { type: "application/octet-stream" });
}

// Blueprint 2
export function excel(devices, deviceStatusesByDeviceId) {
    const columns = [
        "Nr modułu",
        "Czas",
        "Id inkasenta",
        "id klienta",
        "Adres klienta",
        "Id licznika ZW",
        "Id licznika cw",
        "Id licznika ciepla",
        "stan zw",
        "stan cw",
        "stan ciepla",
        "Zużycie ZW",
        "Zużycie CW",
        "Zużycie ciepła",
        "Opis"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        return [[
            device.name.replace(/^38f7cd00/, ''),
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            device.collectorId,
            device.clientId,
            device.clientAddress,
            chooseMeterId(device, "COLD"),
            chooseMeterId(device, "HOT"),
            chooseMeterId(device, "HEAT"),
            statuses && statuses.length > 0 ? calculateState(device, statuses[0], "COLD").value : null,
            statuses && statuses.length > 0 ? calculateState(device, statuses[0], "HOT").value : null,
            statuses && statuses.length > 0 ? calculateState(device, statuses[0], "HEAT").value : null,
            statuses && statuses.length > 0 ? calculateUsage(device, statuses[0], "COLD").value : null,
            statuses && statuses.length > 0 ? calculateUsage(device, statuses[0], "HOT").value : null,
            statuses && statuses.length > 0 ? calculateUsage(device, statuses[0], "HEAT").value : null,
            device.description
        ]]
    });

    const worksheet = XLSX.utils.aoa_to_sheet([columns]
        .concat(rows));
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Metering");

    return new Blob([XLSX.write(new_workbook, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'array'
    })], { type: "application/octet-stream" });
}

// Blueprint 1
export function qnetLeczyce(devices, deviceStatusesByDeviceId) {
    const columns = [
        "Numer wodomierza 1",
        "Czas odczytu",
        "Licznik wodomierza"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.meter1Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD HH:mm:ss") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter2Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD HH:mm:ss") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = [columns]
        .concat(rows)
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function ozarowiceWodnik(devices, deviceStatusesByDeviceId) {
    const columns = [
        "\"Numer wodomierza 1\"",
        "\"Czas odczytu\"",
        "\"Licznik wodomierza\""
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.meter1Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD HH:mm:ss") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter2Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD HH:mm:ss") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = [columns]
        .concat(rows)
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function zbmBytom(devices, deviceStatusesByDeviceId) {
    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.meter1Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD HH:mm:ss") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value : null
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter2Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD HH:mm:ss") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = rows
        .map(e => e.join(","))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function mesznaSw(devices, deviceStatusesByDeviceId) {
    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const clientId = device.clientId.split(/\s+/);
        const clientAddress = device.clientAddress.split(/[\s\/]+/);

        const meter1 = device.meter1Active ? [
            device.name.replace(/^38f7cd00/, ''),
            device.meter1Id,
            clientId.length > 0 ? clientId[0] : null,
            clientId.length > 1 ? clientId[1] : null,
            clientAddress.length > 0 ? clientAddress[0] : null,
            clientAddress.length > 1 ? clientAddress[1] : null,
            clientAddress.length > 2 ? clientAddress[2] : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString().replace(/\./g, ',') : null,
            device.meter1InitialIndex ? (device.meter1InitialIndex / 1000).toString().replace(/\./g, ',') : null
        ] : null;

        const meter2 = device.meter2Active ? [
            device.name.replace(/^38f7cd00/, ''),
            device.meter2Id,
            clientId.length > 0 ? clientId[0] : null,
            clientId.length > 1 ? clientId[1] : null,
            clientAddress.length > 0 ? clientAddress[0] : null,
            clientAddress.length > 1 ? clientAddress[1] : null,
            clientAddress.length > 2 ? clientAddress[2] : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString().replace(/\./g, ',') : null,
            device.meter2InitialIndex ? (device.meter2InitialIndex / 1000).toString().replace(/\./g, ',') : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = rows
        .map(e => e.map(a => "\"" + a + "\"").join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function brzegDolnyUnisoft(devices, deviceStatusesByDeviceId) {
    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.meter1Id,
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter1WaterType).value, 0) : null
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter2Id,
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter2WaterType).value, 0) : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = rows
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function systemeg(devices, deviceStatusesByDeviceId) {
    const columns = [
        "Numer licznika",
        "Numer ewidencyjny licznika",
        "Data odczytu",
        "Wartość odczytu"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.meter1Sn,
            device.meter1Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter1WaterType).value, 2) : null
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter2Sn,
            device.meter2Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], device.meter2WaterType).value, 2) : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const worksheet = XLSX.utils.aoa_to_sheet([columns].concat(rows));
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Metering SYSTEmEG");

    return new Blob([XLSX.write(new_workbook, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'array'
    })], { type: "application/octet-stream" });
}

// Blueprint 2
export function systemeg1(devices, deviceStatusesByDeviceId) {
    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        return [[
            device.name,
            chooseMeterSn(device, "COLD"),
            chooseMeterSn(device, "HOT"),
            chooseMeterId(device, "COLD"),
            chooseMeterId(device, "HOT"),
            device.clientAddress,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            statuses && statuses.length > 0 ? calculateState(device, statuses[0], "COLD").value : null,
            statuses && statuses.length > 0 ? calculateState(device, statuses[0], "HOT").value : null
        ]]
    });

    const worksheet = XLSX.utils.aoa_to_sheet(rows);
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Metering SYSTEmEG1");

    return new Blob([XLSX.write(new_workbook, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'array'
    })], { type: "application/octet-stream" });
}

// Blueprint 1
export function gdanskPoludnieZielonaGoraRtbs(devices, deviceStatusesByDeviceId) {
    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.meter1Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter2Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = rows
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function gdanskPoludnieZielonaGoraRtbs2(devices, deviceStatusesByDeviceId) {
    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);
        const clientAddress = device.clientAddress ? device.clientAddress.split(/[\s\/]+/) : [];

        const meter1 = device.meter1Active ? [
            device.meter1Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString().replace(/\./g, ',') : null,
            clientAddress.length > 2 ? clientAddress[2] : null,
        ] : null;

        const meter2 = device.meter2Active ? [
            device.meter2Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString().replace(/\./g, ',') : null,
            clientAddress.length > 2 ? clientAddress[2] : null,
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = rows
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function granit(devices, deviceStatusesByDeviceId) {
    const columns = [
        "Numer_Licznika",
        "Data_Odczytu",
        "Odczyt"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        if (statuses && statuses.length > 0) {
            const meter1 = calculateActive(device, "COLD") ? [
                chooseMeterId(device, "COLD"),
                moment(statuses[0].time).format("DD.MM.YYYY"),
                calculateState(device, statuses[0], "COLD").value
            ] : null;
            const meter2 = calculateActive(device, "HOT") ? [
                chooseMeterId(device, "HOT"),
                moment(statuses[0].time).format("DD.MM.YYYY"),
                calculateState(device, statuses[0], "HOT").value
            ] : null;

            if (meter1 && meter2) {
                return [meter1, meter2];
            } else if (meter1) {
                return [meter1];
            } else if (meter2) {
                return [meter2];
            } else {
                return [];
            }
        } else {
            return [];
        }
    });

    const csv = [columns]
        .concat(rows)
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 2
export function wodaTrabkiWielkie(devices, deviceStatusesByDeviceId) {
    const columns = [
        "L.P.",
        "Nr modułu",
        "Nazwisko",
        "Imię",
        "Id lokalu",
        "Ulica",
        "Nr domu",
        "Nr mieszkania",
        "Miasto",
        "Numer wodomierza 1",
        "Wartość początkowa 1",
        "Numer wodomierza 2",
        "Wartość początkowa 2",
        "Czas odczytu",
        "Czas radio",
        "Licznik wodomierza 1 [m^3]",
        "Licznik wodomierza 2 [m^3]"
    ];

    const rows = devices.flatMap((device, index) => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const clientId = device.clientId ? device.clientId.split(/\s+/) : [];
        const clientAddress = device.clientAddress ? device.clientAddress.split(/[\s\/]+/) : [];
        return [[
            index + 1,
            device.name != null ? device.name.substring(device.name.length - 10) : null,
            clientId.length > 0 ? clientId[0] : null,
            clientId.length > 1 ? clientId[1] : null,
            "",
            clientAddress.length > 0 ? clientAddress[0] : null,
            clientAddress.length > 1 ? clientAddress[1] : null,
            clientAddress.length > 2 ? clientAddress[2] : null,
            clientAddress.length > 3 ? clientAddress[3] : "Trąbki Wielkie",
            device.meter1Id,
            device.meter1InitialIndex ? (device.meter1InitialIndex / 1000).toString().replace(/\./g, ',') : null,
            device.meter2Id,
            device.meter2InitialIndex ? (device.meter2InitialIndex / 1000).toString().replace(/\./g, ',') : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD.MM.YYYY HH:mm:ss") : null,
            statuses && statuses.length > 0 ? moment(statuses[0].recvTime).format("DD.MM.YYYY HH:mm:ss") : null,
            statuses && statuses.length > 0 && statuses[0].meter1Index ? ((statuses[0].meter1Index.value * device.meter1Multiplier + device.meter1InitialIndex - device.meter1InternalInitialIndex) / 1000).toString().replace(/\./g, ',') : null,
            statuses && statuses.length > 0 && statuses[0].meter2Index ? ((statuses[0].meter2Index.value * device.meter2Multiplier + device.meter2InitialIndex - device.meter2InternalInitialIndex) / 1000).toString().replace(/\./g, ',') : null
        ]];
    });

    const csv = rows
        .map(e => "\"" + e.join("\";\"") + "\"")
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function smJutrzenka(devices, deviceStatusesByDeviceId) {
    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.clientId,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            device.meter1Id,
            statuses && statuses.length > 0 ? (calculateState(device, statuses[0], device.meter1WaterType).value ? round(calculateState(device, statuses[0], device.meter1WaterType).value, 2).toString().replace(/\./g, ',') : null) : null,
            device.clientAddress
        ] : null;

        const meter2 = device.meter2Active ? [
            device.clientId,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            device.meter2Id,
            statuses && statuses.length > 0 ? (calculateState(device, statuses[0], device.meter2WaterType).value ? round(calculateState(device, statuses[0], device.meter2WaterType).value, 2).toString().replace(/\./g, ',') : null) : null,
            device.clientAddress
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const rowsIndexed = rows.map((r, index) => {
        return [
            index + 1,
            r[0],
            r[1],
            r[2],
            r[3],
            r[4]
        ];
    });

    const csv = rowsIndexed
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function ustroniePapirus(devices, deviceStatusesByDeviceId) {
    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.clientId,
            device.meter1Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            statuses && statuses.length > 0 ? (calculateState(device, statuses[0], device.meter1WaterType).value ? round(calculateState(device, statuses[0], device.meter1WaterType).value, 2).toString().replace(/\./g, ',') : null) : null
        ] : null;

        const meter2 = device.meter2Active ? [
            device.clientId,
            device.meter2Id,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            statuses && statuses.length > 0 ? (calculateState(device, statuses[0], device.meter2WaterType).value ? round(calculateState(device, statuses[0], device.meter2WaterType).value, 2).toString().replace(/\./g, ',') : null) : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const rowsIndexed = rows.map((r, index) => {
        return [
            index + 1,
            r[0],
            r[1],
            r[2],
            r[3]
        ];
    });

    const csv = rowsIndexed
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function gliwiceZbm(devices, deviceStatusesByDeviceId) {
    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.name,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString().replace(/\./g, ',') : null,
            device.meter1Id,
            device.clientAddress
        ] : null;

        const meter2 = device.meter2Active ? [
            device.name,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString().replace(/\./g, ',') : null,
            device.meter2Id,
            device.clientAddress
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = rows
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 2
export function jasloWiertnik(devices, deviceStatusesByDeviceId) {
    const columns = [
        "L.p.",
        "Data",
        "Adres Klienta",
        "Id klienta",
        "Opis",
        "ID licznika ZW",
        "Stan ZW",
        "Zużycie ZW",
        "ID licznika CW",
        "Stan CW",
        "Zużycie CW"
    ];

    const rows = devices.flatMap((device, index) => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        return [[
            index + 1,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD.MM.YYYY HH:mm:ss") : null,
            device.clientAddress,
            device.clientId,
            device.description,
            chooseMeterId(device, "COLD"),
            statuses && statuses.length > 0 && calculateState(device, statuses[0], "COLD").value ? calculateState(device, statuses[0], "COLD").value.toString().replace(/\./g, ',') : null,
            statuses && statuses.length > 0 && calculateUsage(device, statuses[statuses.length - 1], statuses[0], "COLD").value ? calculateUsage(device, statuses[statuses.length - 1], statuses[0], "COLD").value.toString().replace(/\./g, ',') : null,
            chooseMeterId(device, "HOT"),
            statuses && statuses.length > 0 && calculateState(device, statuses[0], "HOT").value ? calculateState(device, statuses[0], "HOT").value.toString().replace(/\./g, ',') : null,
            statuses && statuses.length > 0 && calculateUsage(device, statuses[statuses.length - 1], statuses[0], "HOT").value ? calculateUsage(device, statuses[statuses.length - 1], statuses[0], "HOT").value.toString().replace(/\./g, ',') : null
        ]];
    });

    const csv = [columns]
        .concat(rows)
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function ziebiceZwik(devices, deviceStatusesByDeviceId) {
    // L.P.
    // Nr Modułu - zaczynający się od 00 czyli np. 0010031828
    // Nazwisko
    // Imię
    // Miasto - tu kolumna będzie pusta
    // Ulica - pusta kolumna
    // Adres
    // Nr domu
    // Nr mieszkania
    // Nr wodomierza - dwa razy ta sama kolumna(jeżeli wodomierze będą podwójne to musi być jeden pod drugim)
    // Nr wodomierza
    // Czas odczytu - w tej i poniższej kolumnie mogą być te same czasy
    // Czas radio
    // licznik wodomierza

    const columns = [
        "L.P.",
        "Nr Modułu",
        "Nazwisko",
        "Imię",
        "Miasto",
        "Ulica",
        "Adres",
        "Nr domu",
        "Nr mieszkania",
        "Nr wodomierza 1",
        "Nr wodomierza 2",
        "Czas odczytu",
        "Czas radio",
        "licznik wodomierza"
    ];

    const rows = devices.flatMap(device => {
        const statuses = deviceStatusesByDeviceId.get(device.id);
        const clientId = device.clientId ? device.clientId.split(/\s+/) : [];
        const clientAddress = device.clientAddress ? device.clientAddress.split(/[\s\/]+/) : [];

        const meter1 = device.meter1Active ? [
            "",
            device.name.replace(/^38f7cd/, ''),
            clientId.length > 1 ? clientId[1] : null,
            clientId.length > 0 ? clientId[0] : null,
            "",
            "",
            clientAddress.length > 0 ? clientAddress[0] : null,
            clientAddress.length > 1 ? clientAddress[1] : null,
            clientAddress.length > 2 ? clientAddress[2] : null,
            device.meter1Id,
            "",
            statuses && statuses.length > 0 ? moment(statuses[0].recvTime).format("YYYY-MM-DD") : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter1WaterType).value ? calculateState(device, statuses[0], device.meter1WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        const meter2 = device.meter2Active ? [
            "",
            device.name.replace(/^38f7cd/, ''),
            clientId.length > 1 ? clientId[1] : null,
            clientId.length > 0 ? clientId[0] : null,
            "",
            "",
            clientAddress.length > 0 ? clientAddress[0] : null,
            clientAddress.length > 1 ? clientAddress[1] : null,
            clientAddress.length > 2 ? clientAddress[2] : null,
            device.meter2Id,
            "",
            statuses && statuses.length > 0 ? moment(statuses[0].recvTime).format("YYYY-MM-DD") : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], device.meter2WaterType).value ? calculateState(device, statuses[0], device.meter2WaterType).value.toString().replace(/\./g, ',') : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = [columns]
        .concat(rows
            .map((row, index) => {
                return [
                    index + 1,
                    row[1],
                    row[2],
                    row[3],
                    row[4],
                    row[5],
                    row[6],
                    row[7],
                    row[8],
                    row[9],
                    row[10],
                    row[11],
                    row[12],
                    row[13]
                ];
            }))
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 2
export function krasiczynGmina(devices, deviceStatusesByDeviceId) {
    const columns = [
        "nr ewidencyjny",
        "nr wodomierza",
        "nr modułu",
        "data",
        "stan zw",
        "alarmy"
    ];

    const rows = devices.flatMap((device, index) => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        return [[
            "",
            chooseMeterId(device, "COLD"),
            device.name != null ? device.name.substring(device.name.length - 10) : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], "COLD").value ? calculateState(device, statuses[0], "COLD").value.toString().replace(/\./g, ',') : null,
            ""
        ]];
    });

    const csv = [columns]
        .concat(rows)
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function pelplinPelkom(devices, deviceStatusesByDeviceId) {
    const columns = [
        "Numer fabryczny licznika",
        "Adres",
        "Miasto",
        "Zarejestrowana objętość 1",
        "Czas odczytu",
        "Ilość 1"
    ];

    const rows = devices.flatMap((device, index) => {
        const statuses = deviceStatusesByDeviceId.get(device.id);
        const clientAddress = device.clientAddress.split(/[\s]+/);

        const meter1 = device.meter1Active ? [
            chooseMeterId(device, "COLD"),
            device.clientAddress,
            "",
            statuses && statuses.length > 0 && calculateState(device, statuses[0], "COLD").value ? calculateState(device, statuses[0], "COLD").value.toString().replace(/\./g, ',') : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD-MM-YYYY HH:mm") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], "COLD").value ? calculateState(device, statuses[0], "COLD").value.toString().replace(/\./g, ',') : null
        ] : null;

        const meter2 = device.meter2Active ? [
            chooseMeterId(device, "HOT"),
            device.clientAddress,
            "",
            statuses && statuses.length > 0 && calculateState(device, statuses[0], "HOT").value ? calculateState(device, statuses[0], "HOT").value.toString().replace(/\./g, ',') : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD-MM-YYYY HH:mm") : null,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], "HOT").value ? calculateState(device, statuses[0], "HOT").value.toString().replace(/\./g, ',') : null
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = [columns]
        .concat(rows)
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}

// Blueprint 1
export function wilkowiceSw(devices, deviceStatusesByDeviceId) {
    const columns = [
        "nr modułu",
        "data",
        "miasto",
        "Imię i Nazwisko",
        "Adres",
        "ID licznika",
        "Stan wodomierza",
        "kod odbiorcy"
    ];

    const rows = devices.flatMap((device, index) => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        const meter1 = device.meter1Active ? [
            device.name != null ? device.name.substring(device.name.length - 10) : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
            device.collectorId,
            device.clientId,
            device.clientAddress,
            device.meter1Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], "COLD").value ? calculateState(device, statuses[0], "COLD").value.toString().replace(/\./g, ',') : null,
            device.description
        ] : null;

        const meter2 = device.meter2Active ? [
            device.name != null ? device.name.substring(device.name.length - 10) : null,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
            device.collectorId,
            device.clientId,
            device.clientAddress,
            device.meter2Id,
            statuses && statuses.length > 0 && calculateState(device, statuses[0], "HOT").value ? calculateState(device, statuses[0], "HOT").value.toString().replace(/\./g, ',') : null,
            device.description
        ] : null;

        if (meter1 && meter2) {
            return [meter1, meter2];
        } else if (meter1) {
            return [meter1];
        } else if (meter2) {
            return [meter2];
        } else {
            return [];
        }
    });

    const csv = [columns]
        .concat(rows)
        .map(e => e.join(";"))
        .join("\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}


// Każdy wiersz to jedno mieszkanie i patrząc od lewej
// - identyfikator mieszkania (niezmienny), Wiersze są ustawione chronologicznie od 1 do 20 mieszkania.
// - rodzaj odczytu 1 lub 3 (niezmienny)
// - data odczytu (zmienny)
// - ilość liczników na mieszkaniu. 3 lokale są z 2 wodomierzami i wiersze różnią się, że zamiast 4 jest cyfra 2 (niezmienne)
// - liczba porządkowa czyli 1 - zimna kuchnia, 2 -ciepła kuchnia 3- zimna łazienka 4 - ciepła łazienka. (niezmienne)
// - stan licznika to liczby 111.111, 222.222, 333.333, 444.444 (zmienne).


// START ODCZYTY;
// :07021001,1,12/05/2023,4,,,,1,111.111,0,2,222.222,0,3,333.333,0,4,444.444,0;
// :07021002,1,12/05/2023,2,,,,1,111.111,0,2,222.222,0;
// :07021003,1,12/05/2023,4,,,,1,111.111,0,2,222.222,0,3,333.333,0,4,444.444,0;
// :07021004,1,12/05/2023,4,,,,1,111.111,0,2,222.222,0,3,333.333,0,4,444.444,0;
// :07021005,1,12/05/2023,4,,,,1,111.111,0,2,222.222,0,3,333.333,0,4,444.444,0;
// :07021006,1,12/05/2023,4,,,,1,111.111,0,2,222.222,0,3,333.333,0,4,444.444,0;
// :07021007,1,12/05/2023,4,,,,1,111.111,0,2,222.222,0,3,333.333,0,4,444.444,0;
// :07021008,1,12/05/2023,4,,,,1,111.111,0,2,222.222,0,3,333.333,0,4,444.444,0;
// :07021009,1,12/05/2023,4,,,,1,111.111,0,2,222.222,0,3,333.333,0,4,444.444,0;
// :07002010,1,12/05/2023,4,,,,1,111.111,0,2,222.222,0,3,333.333,0,4,444.444,0;
// :07021011,1,12/05/2023,2,,,,1,111.111,0,2,222.222,0;
// :07021012,1,12/05/2023,2,,,,1,111.111,0,2,222.222,0;
// :07002013,1,12/05/2023,4,,,,1,111.111,0,2,222.222,0,3,333.333,0,4,444.444,0;
// :07021014,1,12/05/2023,4,,,,1,111.111,0,2,222.222,0,3,333.333,0,4,444.444,0;
// :07021015,1,12/05/2023,4,,,,1,111.111,0,2,222.222,0,3,333.333,0,4,444.444,0;
// :07021016,1,12/05/2023,4,,,,1,111.111,0,2,222.222,0,3,333.333,0,4,444.444,0;
// :07021017,1,12/05/2023,4,,,,1,111.111,0,2,222.222,0,3,333.333,0,4,444.444,0;
// :07021018,1,12/05/2023,4,,,,1,111.111,0,2,222.222,0,3,333.333,0,4,444.444,0;
// :07021019,1,12/05/2023,4,,,,1,111.111,0,2,222.222,0,3,333.333,0,4,444.444,0;
// :07021020,1,12/05/2023,4,,,,1,111.111,0,2,222.222,0,3,333.333,0,4,444.444,0;
// KONIEC ODCZYTY;

// Blueprint 2
export function chojnickieTbs(devices, deviceStatusesByDeviceId) {
    const rows = devices.flatMap((device, index) => {
        const statuses = deviceStatusesByDeviceId.get(device.id);

        return [[
            ":" + device.clientId,
            1,
            statuses && statuses.length > 0 ? moment(statuses[0].time).format("DD/MM/YYYY") : null,
            2,
            "",
            "",
            "",
            1,
            device.description === "kuchnia" ? statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], "COLD").value, 2) : 0 : 0,
            0,
            2,
            device.description === "kuchnia" ? statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], "HOT").value, 2) : 0 : 0,
            0,
            3,
            device.description === "łazienka" ? statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], "COLD").value, 2) : 0 : 0,
            0,
            4,
            device.description === "łazienka" ? statuses && statuses.length > 0 ? round(calculateState(device, statuses[0], "HOT").value, 2) : 0 : 0,
            0
        ]];
    });

    const rows2 = Object.entries(groupBy(rows, 0)).map(entry => {
        const key = entry[0];
        const rs = entry[1];

        if (rs.length == 1) {
            return [
                key,
                rs[0][1],
                rs[0][2],
                2,
                rs[0][4],
                rs[0][5],
                rs[0][6],

                1,
                rs[0][8] + rs[0][14],
                rs[0][9] + rs[0][15],
                2,
                rs[0][11] + rs[0][17],
                rs[0][12] + rs[0][18]
            ];
        } else if (rs.length = 2) {
            return [
                key,
                rs[0][1],
                rs[0][2],
                4,

                rs[0][4],
                rs[0][5],
                rs[0][6],

                1,
                rs[0][8] + rs[1][8],
                rs[0][9] + rs[1][9],
                2,
                rs[0][11] + rs[1][11],
                rs[0][12] + rs[1][12],

                3,
                rs[0][14] + rs[1][14],
                rs[0][15] + rs[1][15],
                4,
                rs[0][17] + rs[1][17],
                rs[0][18] + rs[1][18]
            ];
        } else {
            return [];
        }
    });

    const sortedRows = rows2.sort((a, b) => (a[0] < b[0]) ? -1 : ((b[0] > a[0]) ? 1 : 0));

    const csv = [["START ODCZYTY"]].concat(sortedRows).concat([["KONIEC ODCZYTY;"]])
        .map(e => e.join(","))
        .join(";\n");

    return new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
}
